.video{
    margin-left: 25px;
    margin-right: 25px;
    position: relative;
    /* border: 1px solid rgb(0, 255, 213);   */
    padding: 100px 25px 100px 25px;
     /* border: 1px solid red;   */
     margin-bottom: 50px;
     margin-top: 100px;
}
.box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    gap: 25px;
}
.image{
    width: 500px;
    height: 400px;
}
.image video{
   width:100%;
   height: 100%;
   object-fit: cover;
}
.box .text{
    width:400px;
    align-self: flex-end;
}
.box .text h5{
    color: #fff;
    font-size: 30px;
    font-weight: 400;
}
.box .text p{
    color: rgb(70, 70, 70);
    margin-top: 25px;
    width: 75%;
}
.big{
    position: absolute;
    top: 0;
    right: 0;
}
.m-text{
    
}
/* .m-text .color{
    font-weight: 700;
    font-size:150px;
    -webkit-text-fill-color: rgb(60, 69, 152);
    transition: 2s ease-in-out;
} */

.m-text-two h1{
    font-weight: 900;
    font-size:150px;
    -webkit-text-stroke:3px rgb(209, 209, 209);
   
    /* -webkit-text-fill-color: transparent; */
    transition: 1s ease-in-out;
}
.m-text h1{
    font-weight: 900;
    font-size:150px;
  
    transition: 1s ease-in-out;
}
/* .video:hover .m-text-two h1{
    -webkit-text-fill-color: #fff;
    transition: 1s ease-in-out;
} */
/* .video:hover .m-text h1{
    transition:1s ease-in-out;
    -webkit-text-stroke: 2px rgb(60, 56, 134);
    -webkit-text-fill-color: transparent;
} */
.more{
    position: absolute;
    top:350px;
    right: 0;
}
.more button{
    text-transform: capitalize;
    font-size: 18px;
    padding: 25px 50px 25px 50px;
  
    outline: none;
    border: none;
    color: #fff;
}
/* .video:hover button{
    background:rgb(255, 255, 255) ;
    color: rgb(60, 56, 134);
}
 */

 .m-text .green{
    -webkit-text-fill-color: rgb(44, 119, 93);
 }
.m-text-two .green{
    -webkit-text-fill-color: rgb(44, 119, 93);
}
.m-text .brown{
    -webkit-text-fill-color: rgb(192, 151, 46);
 }
 .m-text-two  .brown{
    -webkit-text-fill-color: rgb(192, 151, 46);
 } 
 .green{

 }


 
@media screen and (max-width:640px) {
    .image{
        width:800px;
        height:200px;
    }
    .image video{
        width:100%;
        height: 100%;
     }
    .m-text-two h1{
        font-weight: 900;
        font-size:75px;
        -webkit-text-stroke:3px rgb(209, 209, 209);
        /* -webkit-text-fill-color: transparent; */
        -webkit-text-fill-color: #fff;
    }
    .m-text h1{
        font-weight: 700;
        font-size:75px;
        /* -webkit-text-stroke: 2px rgb(60, 56, 134); */
        -webkit-text-fill-color: rgb(60, 69, 152);
        transition: 2s ease-in-out;
    }
    .box .text h5{
        color: #fff;
        font-size: 20px;
        font-weight: 300;
    }
    .box .text p{
        color: rgb(70, 70, 70);
        margin-top: 25px;
        width: 100%;
    }
    .box .text{
        width:400px;
        align-self: flex-end;
    }
    .more button{
        text-transform: capitalize;
        font-size: 18px;
        padding: 10px 25px 10px 25px;
      
        outline: none;
        border: none;
        color: #fff;
    }
}