.nav{
    margin-right: 25px;
    margin-left: 25px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav .logo img{
    width: 100px;
    cursor: pointer;
}
.nav .menu i{
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}