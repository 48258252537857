.video{
    margin-left: 25px;
    margin-right: 25px;
    position: relative;
    /* border: 1px solid red;   */
    padding: 100px 25px 100px 25px;
}
.box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    gap: 25px;
}
.image2{
    width: 500px;
    height:400px;
}
.image2 video{
   width:100%;
   height: 100%;
   object-fit: cover;
}
.box .text{
    width:400px;
    align-self: flex-end;
}
.box .text h5{
    color: #fff;
    font-size: 30px;
    font-weight: 400;
}
.box .text p{
    color: rgb(70, 70, 70);
    margin-top: 25px;
    width: 75%;
}
.big2{
    position: absolute;
    top: 0;
    left: 0;
}
/* .m-text-m{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} */
.m-text-m h1{
    font-weight: 700;
    font-size:150px;
    /* -webkit-text-stroke: 2px rgb(60, 56, 134); */
    -webkit-text-fill-color: rgb(60, 69, 152);
    transition: 2s ease-in-out;
}
/* .m-text-two-m{
    position: absolute;
    top:150px;
    left: 0;
} */
.m-text-two-m h1{
    font-weight: 900;
    font-size:150px;
    -webkit-text-stroke:3px rgb(209, 209, 209);
    /* -webkit-text-fill-color: transparent; */
    -webkit-text-fill-color: #fff;
}
/* .video:hover .m-text-two-m h1{
    -webkit-text-fill-color: #fff;
    transition: 2s ease-in-out;
}
.video:hover .m-text-m h1{
    -webkit-text-fill-color: transparent;
    transition: 2s ease-in-out;
} */
.more-m{
    position: absolute;
    top:350px;
}
.more-m button{
    text-transform: capitalize;
    font-size: 18px;
    padding: 25px 50px 25px 50px;
    background:rgb(134, 56, 56) ;
    outline: none;
    border: none;
    color: #fff;
}
/* .video:hover button{
    background:rgb(255, 255, 255) ;
    color: rgb(60, 56, 134);
} */


@media screen and (max-width:640px) {
    .image2{
        width:800px;
        height:200px;
    }
    .image2 video{
        width:100%;
        height: 100%;
     }
    .m-text-two-m h1{
        font-weight: 900;
        font-size:75px;
        -webkit-text-stroke:3px rgb(209, 209, 209);
        /* -webkit-text-fill-color: transparent; */
        -webkit-text-fill-color: #fff;
    }
    .m-text-m h1{
        font-weight: 700;
        font-size:75px;
        /* -webkit-text-stroke: 2px rgb(60, 56, 134); */
        -webkit-text-fill-color: rgb(60, 69, 152);
        transition: 2s ease-in-out;
    }
    .box .text h5{
        color: #fff;
        font-size: 20px;
        font-weight: 300;
    }
    .box .text p{
        color: rgb(70, 70, 70);
        margin-top: 25px;
        width: 100%;
    }
    .box .text{
        width:400px;
        align-self: flex-end;
    }
    .more button{
        text-transform: capitalize;
        font-size: 18px;
        padding: 10px 25px 10px 25px;
      
        outline: none;
        border: none;
        color: #fff;
    }
}